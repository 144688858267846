<template>
  <div>
    <b-table-custom :table-data="dataObjectList"
                    :table-columns="columns"
                    :is-data-loading="isTableDataLoading"
    >
      <template #button_event>
        <b-button size="sm" variant="primary" @click="openModal('modal-create')">
          <i class="fas fa-plus mr-50"/>
          <span>Thêm Mới</span>
        </b-button>
      </template>
      <template #menu="data">
        <template v-for="menu in menuButtonItems">
          <b-dropdown-item @click="menu.onClick(data.item)">
            <i :class="`${menu.icon} text-${menu.color} mr-50`"/>
            <span>{{ menu.label }}</span>
          </b-dropdown-item>
        </template>
      </template>

      <template #cell(name)="{data}">
        <strong>{{ data['item']['name'] }}</strong>
      </template>
      <template #cell(buying_price)="{data}">
        <strong class="text-success">{{ currencyFormat(data['item']['buying_price']) }}</strong>
      </template>
      <template #cell(selling_price)="{data}">
        <strong class="text-info">{{ currencyFormat(data['item']['selling_price']) }}</strong>
      </template>
      <template #cell(actual_buying_price)="{data}">
        <strong>{{ currencyFormatUSD(data['item']['actual_buying_price']) }}</strong>
      </template>
    </b-table-custom>

    <custom-modal id="addon-modal"
                  ref="addon-modal"
                  :title="currentModal.title"
                  :ok-title="currentModal.okTitle"
                  :cancel-title="currentModal.cancelTitle"
                  :variant="currentModal.color"
                  :is-manual-close="currentModal.manualClose"
                  @ok="currentModal.confirmAction"
    >
      <b-card-text v-if="modalName === 'modal-update' ||  modalName === 'modal-create'">
        <custom-input v-model="dataObjectSelectedClone['name']" label="Name" />
        <b-row>
          <b-col md="6" cols="12">
            <custom-input label="Value" v-model="dataObjectSelectedClone['value']" color="success"/>
          </b-col>
          <b-col md="6" cols="12">
            <custom-input label="Unit" v-model="dataObjectSelectedClone['unit']" color="info"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" cols="12">
            <money-box label="Selling price" v-model="dataObjectSelectedClone['selling_price']" color="info"/>
          </b-col>
          <b-col md="6" cols="12">
            <custom-input type="number" label="Actual buying price ($)" v-model="dataObjectSelectedClone['actual_buying_price']"/>
          </b-col>
        </b-row>
        <custom-input v-model="dataObjectSelectedClone['note']" label="Note" />
        <switch-button v-model="dataObjectSelectedClone['is_active']" true-label="Active" false-label="Inactive"/>
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-delete'">
        <p>Bạn có chắc chắn muốn xoá addon name <strong>{{ dataObjectSelectedClone['name'] }}</strong>?</p>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>
import DataRequester from "@/mixins/utils/dataRequester";
import InputHelper from "@/mixins/utils/inputHelper";
import DataObjectList from "@/mixins/utils/dataObjectList";
import addonService from "@/mixins/utils/axios/addon.service";

import BTableCustom from "@/views/components/table/BTableCustom.vue";
import CustomModal from "@/views/components/CustomModal.vue";
import CustomInput from "@/views/components/form/CustomInput.vue";
import SwitchButton from "@/views/components/form/SwitchButton.vue";
import MoneyBox from "@/views/components/MoneyBox.vue";

export default {
  name: 'Addon',
  mixins: [DataRequester, InputHelper, DataObjectList],
  components: {
    SwitchButton, CustomInput,
    BTableCustom,
    CustomModal,
    MoneyBox
  },
  computed: {
    menuButtonItems: function () {
      let self = this
      return [
        {
          label: 'Cập nhật',
          icon: 'fas fa-edit',
          color: 'primary',
          onClick: function (data) {
            self.openModal('modal-update', data)
          }
        },
        {
          label: 'Xoá',
          icon: 'fas fa-trash-alt',
          color: 'danger',
          onClick: function (data) {
            self.openModal('modal-delete', data)
          }
        }
      ]
    },
    currentModal: function () {
      switch (this.modalName) {
        case 'modal-update':
          return {
            title: 'Cập nhật thông tin',
            okTitle: 'Cập nhật',
            cancelTitle: 'Đóng',
            color: 'primary',
            manualClose: true,
            confirmAction: this.updateAddon
          }
        case 'modal-delete':
          return {
            title: 'Xoá thông tin',
            okTitle: 'Xoá',
            cancelTitle: 'Đóng',
            color: 'danger',
            manualClose: true,
            confirmAction: this.deleteAddon
          }
        case 'modal-create':
          return {
            title: 'Thêm mới',
            okTitle: 'Thêm',
            cancelTitle: 'Đóng',
            color: 'success',
            manualClose: true,
            confirmAction: this.insertAddon
          }
        default:
          return {
            confirmAction: function () {}
          }
      }
    }
  },
  data () {
    return {
      isTableDataLoading: false,
      columns: [
        {key: 'name', label: 'Name'},
        {key: 'buying_price'},
        {key: 'selling_price'},
        {key: 'actual_buying_price'},
        {key: 'note'},
        {key: 'is_active'},
        {key: 'menu'}
      ],
      modalName: '',
    }
  },
  created () {
    this.listAllAddons()
  },
  methods: {
    openModal (modalName, data) {
      this.modalName = modalName
      this.dataObjectSelected = data
      this.dataObjectSelectedClone = {...data}
      this.$refs['addon-modal'].show()
    },
    closeModal () {
      this.$refs['addon-modal'].hide()
    },

    listAllAddons () {
      let self = this
      this.isTableDataLoading = true
      this.runApi(addonService.listAllAddons(), function (result) {
        self.dataObjectList = result.data
      }, undefined, function () {
        self.isTableDataLoading = false
      })
    },
    deleteAddon () {
      let self = this
      this.runApi(addonService.deleteAddon(this.dataObjectSelectedClone['id']), function () {
        self.removeRowDataByIds(self.dataObjectSelectedClone['id'])
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    updateAddon () {
      let self = this
      this.runApi(addonService.updateAddon(this.dataObjectSelectedClone), function () {
        self.listAllAddons()
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    insertAddon () {
      let self = this
      this.runApi(addonService.insertAddon(this.dataObjectSelectedClone), function () {
        self.listAllAddons()
      }, undefined, function () {
        self.closeModal()
      }, true)
    }
  }
}
</script>
